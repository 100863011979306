@import 'styles/common.scss';

.ProfileModal {
  width: 460px;
  border-radius: 10px;

  max-height: calc(100% - 80px);

  &Header {
    position: fixed;
    z-index: 101;
    padding: 10px;
    border-radius: 20px 20px 0px 0px;

    background: var(--bg-primary-webapp);
    width: 460px;
    text-align: center;
    @include f-inter(1.6rem, 2.2rem);
    &Close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
  }
  &Content {
    padding: 65px 28px 32px;
    background: var(--bg-primary-webapp);

    .inputCol:first-child {
      margin-bottom: 16px;
    }

    .fieldLabel {
      margin-bottom: 4px;
      @include f-inter(1.4rem, 2rem);
      color: #080c0b;
    }
  }
  &Footer {
    padding: 28px;
    @include flex(center, flex-end);
    gap: 8px;
  }
   .errorMessage {
    @include flex(center);
    color: rgba(229, 19, 46, 1);
    @include f-inter(1.2rem, 1.7rem);
    padding-top: 6px;
    .icon {
      padding-right: 5px;
    }
  }
}
