@import 'styles/common.scss';

.CandidateList {
  
  .selection {
    position: relative;
    background: none;
    padding: 8px;
    &:hover {
      border-radius: 8px;
      background: var(--ui-teal-light-bg-secondary);
    }
    & > input:not(:checked) ~ label{

      .untickDisabled{
        border: 1.5px solid var(--border-on-lights-10);
        background: var(--surface-transparent-secondary-loud );
      }
      .hide-check-box{
        display: none;
      }
    }
  }
 
  .in-event-candidate,.selection {
    .name {
      @include f-inter-regular-2(1.6rem, 2.2rem);
      text-transform: capitalize;
      
    }
    .email {
      @include f-inter-regular(1.3rem, 1.8rem);
      color: var(--Text-Tertiary);
    }
    .name,.email {
      @include multi-line-ellipsis(1);
      word-break: break-all;
    }
  }
  .in-event{
    @include f-inter-regular-2(1.2rem,1.7rem);
    color: var(--text-icon-tertiary);
    border-radius: 6px;
    background: var(--surface-overlay-silent);
    padding: 2.5px 4px;
    &-candidate {
      padding: 8px;
      opacity: 0.5;
      .name,.email {
        color: var(--text-icon-disabled);
      }
    }
    & > input:not(:checked) ~ label{

      .untickDisabled{
        border: 1.5px solid var(--border-on-lights-10);
        background: var(--surface-transparent-secondary-loud );
      }
    }
  }
}
.popover-candidate {
  &-container {
    width: 380px;
    border-radius: 12px;
    padding: 16px 20px;
    background: var(--ui-teal-light-bg-primary);
    box-shadow: 0px 3px 4px 0px rgba(8, 12, 11, 0.07);
  }
  .content {
      color: var(--text-primary);
      padding-bottom: 12px;
  }
  .name {
    @include f-inter-bold(1.8rem, 2.5rem);
    @include multi-line-ellipsis(1);
    flex: 1;
    text-transform: capitalize;
  }
  .iconRow,
  .title {
    @include flex(center);
    column-gap: 12px;
  }
  .detail {
      color: var(--text-primary);
      margin-bottom: 12px;
    &:last-of-type {
      margin: 0;
    }
  }
  .heading {
    @include f-inter-regular-2(1.4rem, 2rem);
    color: var(--text-primary);
    &Detail {
      @include f-inter-regular(1.4rem, 2rem);
      @include multi-line-ellipsis(1);
      word-break: break-all;
    }
  }
  .prefChip {
    @include f-inter-regular-2(1.2rem, 1.7rem);
    color: var(--text-icon-on-lights-tertiary);
    padding: 2.5px 4px;
    border-radius: 6px;
    background: var(--surface-overlay-silent);
    margin-top: 8px;
  }
}
.tooltip{
    position: absolute;
    top: 30%;
    right: 10px;
    z-index: 1;
  
  &Children {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
