@import 'styles/common.scss';

.Sidebar {
  position: sticky;
  top: 0;
  @include flex-column(flex-start);
  width: 100%;
  max-height: 100vh;
  padding: 0;
  border-right: 1px solid var(--border-on-lights-10);
  background: var(--bg-primary);
  color: var(--text-primary-v2);

  .header {
    flex: 0;
    width: 100%;
    padding: 16px;
    cursor: pointer;

    .profile {
      .dropdown-menu {
        padding: 8px 12px;
        width: 100%;
        border: none;
        background: transparent;

        &:hover,
        &.dropdown-open {
          background: var(--ui_teal_light-surface-primary-pressed);
          border-radius: 6px;
        }
        .icon {
          color: var(--text-icon-tertiary);
          transform: rotateX(0deg);
          transition: transform 500ms;
        }
        &[data-headlessui-state="open"] {
          .icon {
            transform: rotateX(180deg);
            transition: transform 500ms;
          }
        }
      }

      .title {
        position: relative;
        cursor: pointer;

        .avatar {
          flex-shrink: 0;
          width: 40px;
          height: 40px;
        }

        .tooltip {
          @include f-inter-regular(1.3rem, 1.82rem);
          border-radius: 12px;
          padding: 8px 12px;
        }
        .tooltip-container {
          position: static;
        }

        .verified-icon {
          @include flex-center;
          position: absolute;
          top: 24px;
          left: 24px;
          width: 16px;
          height: 16px;
          background-color: var(--ui-teal-light-surface-warning);
          color: var(--text-icon-on-lights-warning);
          border-radius: 100px;
          border: 1px solid var(--ui-teal-light-surface-tertiary);

          .starIcon {
            padding: 2px;
          }
        }
        
        .row {
          display: flex;
          align-items: center;
        }
        
        .name {
          @include f-inter-regular-2(1.4rem, 2rem);
          text-align: start;
          padding-right: 8px
        }
        
        .description {
          @include f-inter-regular-2(1.2rem, 1.7rem);
          color: var(--text-icon-on-lights-tertiary);
          text-align: start;
        }
      }

      .options {
        position: absolute;
        top: 56px;
        right: 0;
        background-color: var(--bg-primary);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
        border-radius: 10px;
        padding: 8px;
        width: 160px;
        z-index: $zindex-dropdown;
      }
      .logout {
        @include f-inter-regular(1.5rem, 2.2rem);
        cursor: pointer;
        border: none;
        border-radius: 6px;
        padding: 11px 14px;
        width: 100%;
        height: 44px;
        text-align: left;
        color: var(--black-color);
        background-color: transparent;
        &:hover {
          background-color: var(--ui_teal_light-surface-primary-pressed);
        }
      }
    }
  }

  .section {
    overflow: auto;
    flex-grow: 0;
    padding: 4px 16px;
    width: 100%;

    &Row {
      padding: 10px 12px;
      margin-bottom: 4px;
      cursor: pointer;
      @include f-inter-regular(1.4rem, 2rem);

      &:last-of-type {
        margin: 0;
      }

      &:hover {
        background: #080C0B0D;
        border-radius: 6px;
      }

      .iconActive {
        color: var(--primary-color);
      }

      .textActive {
        font-weight: 600;
      }

      &Active {
        background: #080C0B0D;
        border-radius: 6px;

        &:hover {
          background: #080C0B0D;
        }
      }
    }

    &Icon {
      color: var(--text-icon-tertiary);
      align-self: center;
    }

    .new {
      @include f-inter-regular(1.1rem, 1.5rem);
      @include flex(flex-end);
      background: var(--text_icon-on_lights-critical);
      border-radius: 100px;
      color: var(--accent-text);
      height: 20px;
      min-width: 20px;
      padding: 1.5px 6px 2.5px 6px;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
    }

    .separator {
      margin: 16px 12px;
    }

    .progressCircle {
      align-self: center;

      &Path {
        stroke: var(--primary-color);
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--surface-overlay-loud-plus);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 5px;
    }
  }

  .separator {
    border-bottom: 1px solid var(--border-on-lights-10);
  }

  .footer {
    flex: 0;
    width: 100%;
    background-color: var(--bg-primary);;

    .logo {
      padding: 24px 28px;
      cursor: pointer;
    }
  }
}

.popover {
  max-height: 650px;
  width: 400px;
  overflow-y: hidden;
  border-radius: 8px;
  border: 0.5px solid #080C0B1A;
  box-shadow: 0px 8px 16px 0px #080c0b17;
  z-index: 150;

  .menu {
    padding: 0;
  }

  &Trigger {
    @include flex(unset, space-between);
    margin-bottom: 4px;
  }

  &Row {
    width: 100%;

    .sectionIcon {
      opacity: 0.62;
    }
  }
}

.divForSpacing {
  flex-grow: 1;
  width: 100%;
}