@import 'styles/common.scss';

.PendingInviteCard {
  .cohosting {
    background: #fcf4d6;
    box-shadow: 0px 4px 10px rgba(45, 47, 48, 0.05);
    border-radius: 8px;
    padding: 18px;
    margin-bottom: 24px;
    .info {
      @include f-inter-bold(1.4rem, 2.4rem);
    }
    .mainTitle {
        margin-bottom: 6px;
    }
    .logo {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
    &Info {
      flex: 1 1;
    }
    &Header {
      column-gap: 12px;
    }
    &Desc {
      column-gap: 6px;
      &Toaster {
        word-break: break-word;
        max-width: 400px;
      }
    }
    .buttons {
      @include flex;
      flex-direction: row;
      column-gap: 10px;
    }
    &Button {
      @include f-inter-semi-bold(1.2rem, 1.6rem);
      color: var(--text-primary);
      background: #faf7f7;
      border-radius: 4px;
      padding: 8px 14px;
    }
  }
}
