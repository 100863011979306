@import 'styles/common.scss';

$naviagtion-size-sm: 32px;
$naviagtion-size-lg: 60px;

.SlideShowNavigationButton {
  &:hover {
    background: rgba(8, 12, 11, 0.05);
    border-radius: 50%;
  }
}
.SlideShow {
  position: relative;
  overflow: hidden;
  min-height: $naviagtion-size-lg;
  @include small {
    min-height: $naviagtion-size-sm;
  }
  &Slides {
    white-space: nowrap;
    transition: all 500ms ease-in-out;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &SlideContainer {
    display: inline-block;
    height: 100%;
    position: relative;
    transition: margin-right 500ms ease-in-out;
    vertical-align: bottom;
  }
  .topAlign {
    vertical-align: top;
  }
  &Navigation {
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 10;
    width: $naviagtion-size-lg;
    background-color: rgba(255, 255, 255, 0.75);
    @include small {
      width: $naviagtion-size-sm;
    }
    &Button {
      background: var(--bg-secondary);
      border-radius: 50%;
      @include small {
        background: var(--bg-primary);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
      }
    }
    &Left {
      @include flex(center, center);
      left: 0;
    }
    &Right {
      right: 0;
      @include flex(center, center);
    }
  }
}
