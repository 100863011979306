@import 'styles/common.scss';

.ReferralPopup {
  @include flex(space-around, center);
  position: relative;
  max-height: 650px;
  background: var(--bg-primary-light);
  padding: 24px;
  border-radius: 8px;
  cursor: auto;

  .message {
    margin-bottom: 4px;
  }

  h3 {
    @include f-inter-semi-bold(1.8rem, 2.5rem);
    margin-bottom: 4px;
  }

  p {
    @include f-inter-regular(1.3rem, 1.8rem);
    color: #080C0B9E;
  }

  a {
    @include f-inter-regular-2(1.4rem,2rem);
    color: var(--link-color);
    margin-top: 4px;
  }

  .copyBtn {
    margin: 16px 0px;
  }

  .referrals {
    @include flex(center, space-between);
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 16px;
    background: var(--surface-overlay-minimal);

    .societyReferrals,
    .ownReferrals {
      @include flex-column;
      flex: 1;

      h2 {
        @include f-inter-bold(2rem,2.8rem);
      }

      p {
        @include f-inter-regular-2(1.3rem,1.8rem);
      }
    }
  }

  .progressReferrals {
    height: 355px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: -20px;

    h3 {
      @include f-inter-semi-bold(1.6rem,2.2rem);
      margin-bottom: 16px;
      margin-left: 0px;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #080c0b26;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }

  .startIcon {
    height: 20px;
    width: 20px;
  }
}
