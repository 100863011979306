@import 'styles/common.scss';

.container {
  flex: 1 1 auto;
  height: 100%;
  display: grid;
  grid-template-columns: 279px calc(100% - 279px);
}
.sidebar {
  @include medium {
    height: 100vh;
    height: -webkit-fill-available;
  }
  @include large {
    height: 100vh;
  }
  min-width: 100%;
  background: var(--bg-primary);
  position: sticky;
  top: 0;
  overflow-y: initial;
  padding: 5vh 24px;
  @include xx-large {
    padding: 5vh 48px;
  }
}
.userinfo {
  height: 78px;
}
.logo {
  margin-bottom: 20px;
}
.primaryLinks {
  @include flex-column;
}
.separator {
  margin-top: 40px;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 12px;
}
.primaryLink {
  @include fns-semi-bold(1.4rem, 1.9rem);
  @include flex(center);
  letter-spacing: 0.3px;
  padding: 12px;
  color: var(--text-primary);
  border-radius: 10px;
  margin-bottom: 12px;
  &:last-of-type {
    margin-bottom: 0;
  }
  span {
    display: inline-block;
    margin-left: 10px;
  }
  &.active {
    background: var(--primary-color);
    color: var(--accent-text);
  }
  .dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: var(--complementary-primary);
  }
}
.secondaryLinks {
  @include flex-column;
  padding: 0 12px;
}
.secondaryLink {
  @include fns-regular(1.4rem, 1.9rem);
  color: var(--text-secondary);
  display: inline-block;
  margin-top: 24px;
  &.active {
    color: var(--primary-color);
  }
}
.main {
  @include flex-column;
  height: 100%;
}

.inCompleteProfile{
  margin-bottom: 25px;
}
