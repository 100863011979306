@import 'styles/common.scss';

.AddExternalLinks {
    display: block;
    width: 100%;

    & > button{
        width: 100%;

        button {
            width: 100%;
            border: none;
            padding: 0;
            background: transparent;

            .externalLinksCta {
                border: none;
                padding: 10px 16px;

                div:nth-child(2){
                    width: 12px;
                    height: 12px;
                    margin-left: 8px;

                    svg {
                        width: 12px;
                        height: 12px;
                    }
                }
            }
        }
    }

    &Menu {
        min-width: auto !important;

        & > div {
            border-bottom: none !important;

            button > div {
                padding: 7px 10px !important;
                font-size: 0.875rem !important;
            }
        }
    }
}
