@import 'styles/common.scss';

.filter {
  display: inline-flex;
  white-space: nowrap;

  .trigger {
    @include f-inter-semi-bold(1.4rem, 2rem);
    @include flex(center);
    column-gap: 8px;
    color: var(--text-primary-v2);
    background-color: var(--surface-overlay-silent);
    border-radius: 10px;
    padding: 9px 16px;
    border: 1px solid transparent;
    cursor: pointer;
    &.trigger-selected {
      background-color: var(--primary-color);
      color: var(--text-icon-text-icon-inverted-primary);
    }
  }

  .dropdown-icon {
    &.dropdown-icon-reverse {
      transform: rotateX(180deg);
    }
    &,
    .dropdown-icon-reverse {
      transition: transform 0.2s;
    }
  }

  .dropdown {
    @include flex-column;
    position: absolute;
    top: 100%;
    width: 320px;
    border-radius: 10px;
    background: var(--bg-primary);
    box-shadow: 0px 0px 10px var(--shadow-color);
    z-index: 999;
  }

  .dropdown-search {
    padding: 14px 14px 10px;
    .search-input {
      color: var(--text-primary);
      input {
        @include ui-text-ui-16-regular;
        background-color: var(--ui-teal-light-surface-overlay-silent);
        padding: 7px 12px 7px 33px;
        border: none;
        &::placeholder {
          @include ui-text-ui-16-regular;
          color: var(--text-icon-placeholder);
        }
      }
    }
    .search-icon {
      left: 4px;
      color: var(--text-icon-placeholder);
    }
  }

  .nested-filters {
    @include flex;
    @include hide-scrollbar();
    column-gap: 6px;
    padding: 0 14px 4px 14px;
    overflow: auto;
    .trigger {
      @include f-inter-semi-bold(1.3rem, 1.8rem);
      padding: 7px 12px;
      border-radius: 8px;
      border: none;
      column-gap: 6px;
      &:hover {
        background-color: var(--border-on-lights-10);
      }
      &.trigger-open {
        background-color: var(--border-on-lights-15);
      }
      &.trigger-selected {
        background-color: var(--primary-color);
        color: var(--text-icon-text-icon-inverted-primary);
      }
    }
  }

  .dropdown-options {
    @include flex-column;
    @include scroll-bar-style();
    overflow: auto;
    max-height: 312px;
    padding: 4px;

    .dropdown-option {
      @include flex;
      align-items: center;
      &.dropdown-option-expanded {
        background-color: var(--surface-overlay-silent);
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }

      .checkbox {
        @include flex-center;
        padding: 7px 4px 7px 10px;
        width: 100%;
        &.has-cities {
          padding-left: 2px;
        }
        &.no-filtered-cities {
          padding-left: 24px;
        }

        label {
          @include f-inter-regular-2(1.4rem, 2.2rem);
          color: var(--text-primary);
          width: 100%;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .option-expand-icon {
        height: fit-content;
        padding: 5px;
        border-radius: 4px;
        transform: rotateZ(-90deg);
        &.expanded-icon {
          transform: rotateZ(0);
        }
      }
    }

    .dropdown-sub-option {
      background-color: var(--surface-overlay-silent);
      padding-left: 14px;
      &.last-sub-option {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }
  }

  .noResults {
    @include f-inter-regular-2(1.4rem, 1.9rem);
    padding: 24px 14px;
    text-align: center;
  }

  .spinner {
    width: 100%;
  }

  .dropdown-footer {
    @include flex;
    justify-content: flex-end;
    column-gap: 8px;
    border-top: 1px solid var(--border-border-15);
    padding: 12px 14px;
  }
}
