@import 'styles/common.scss';

$aside-width: 280;
$naviagtion-size-sm: 32px;
$naviagtion-size-lg: 60px;

.ToolFilters {
  display: grid;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &Scrolled {
    position: fixed;
    top: 62px;
    padding: 12px 16px;
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    border-bottom: 1px solid
      var(--border-border-on-lights-15, rgba(8, 12, 11, 0.15));
    padding: 12px 16px;
    width: calc(100% - 279px);
    z-index: 1;
  }

  &Hidden {
    display: none;
  }

  &Wrapper {
    position: relative;
  }
  &List {
    @include flex(center, space-between);
    gap: 8px;
    flex-wrap: nowrap;
    overflow-x: hidden;
    &:has(.multiselect-button[aria-expanded='true']) {
      .tooltip-container {
        display: none;
      }
    }
  }

  &SlideShow {
    &Navigation {
      &Button {
        background: var(--bg-secondary);
        border-radius: 50%;
        padding: 0px;

        &:hover {
          border-radius: 50%;
          background: var(--bg-primary);
        }

        @include small {
          background: var(--bg-primary);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
        }
      }

      &Left {
        @include flex(center, flex-start);
        margin-right: 5px;
        left: 0;
        height: 42px;
        width: 56px;
        background: linear-gradient(
          70deg,
          #eff1f3 39.14%,
          rgba(239, 241, 243, 13%) 100%
        );
        position: absolute;
        z-index: 1;
        &Scrolled {
          left: 16px;
          background: linear-gradient(
            171deg,
            #fff 52.42%,
            rgba(255, 255, 255, 0) 100%
          );
          & > button {
            border: 1.5px solid var(--border-border-15, rgba(8, 12, 11, 0.15));
          }
        }
      }

      &Right {
        margin-left: 5px;
        @include flex(center, flex-end);
        position: absolute;
        right: 0;
        height: 42px;
        width: 56px;
        background: linear-gradient(
          270deg,
          #eff1f3 57.14%,
          rgba(239, 241, 243, 0) 100%
        );
        z-index: 1;
        &Scrolled {
          right: 16px;
          background: linear-gradient(
            270deg,
            #fff 68.42%,
            rgba(255, 255, 255, 0) 100%
          );
          & > button {
            border: 1.5px solid var(--border-border-15, rgba(8, 12, 11, 0.15));
          }
        }
      }
    }
  }
  .multi-select-wrapper {
    position: static;
  }
  .multi-select-dropdown {
    top: 100% !important;
    max-width: 400px;
  }
  &Multiselect {
    border-radius: 10px;
    background: var(--surface-overlay-silent);
    padding: 10px 16px;
    @include f-inter-semi-bold(1.4rem, 2rem);
    p {
      white-space: nowrap;
    }
    &Wrapper {
      position: static;
    }
    &Selected {
      background-color: var(--primary-color);
      color: var(--accent-text);
    }
    &White {
      @include f-inter-semi-bold(1.3rem, 1.8rem);
      color: var(--text-primary-v2);
      padding: 7px 12px;
      border-radius: 8px;
      background: var(--ui-teal-light-bg-primary);
      box-shadow: 0px 1px 3px 0px rgba(8, 12, 11, 0.05);
    }
    &Selected {
      background-color: var(--primary-color);
      color: var(--accent-text);
    }
    &Open {
      background: var(
        --surface-surface-overlay-loud-plus,
        rgba(8, 12, 11, 0.15)
      );
    }
    &Dropdown {
      max-height: 250px;
    }
    &Dropdown {
      max-width: 400px;
    }
    &Disabled {
      color: var(--text-icon-disabled);
      padding: 11px 16px;
      width: auto;
    }
  }
  .tooltip {
    @include f-inter-regular(1.2rem, 1.7rem);
    padding: 4px 8px;
    left: 50%;
    &Scrolled {
      left: 35%;
    }
    &Container {
      position: unset;
    }
  }
}
