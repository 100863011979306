@import 'styles/common.scss';

$progress-bar: #ffa708;
$progress-bar-background: #E5E5E5;


.container {
  @include flex-center;
  position: relative;
  @include fns-semi-bold(1.2rem, 1.9rem);
  & > svg { 
    position: absolute;
    top: 0;
    left: 0;
    stroke-linecap: round;
    &.incomplete {
      .topPath {
        stroke: $progress-bar;
      }
    }
    &.complete {
      .topPath {
        stroke: var(--primary-color);
      }
    }
    &.top {
      transform: 180deg;
    }
    &.bottom {
      transform: rotate(180deg);
    }
    &.strokeColor {
      path {
        stroke: rgba(9, 20, 37, 0.74);
      }
    }
  }
  &.xxxSmall {
    height: 13px;
    width: 13px;
  }
  &.xxSmall {
    height: 20px;
    width: 20px;
  }
  &.extraSmall {
    height: 32px;
    width: 32px;
  }
  &.small {
    height: 42px;
    width: 42px;
  }
  &.medium {
    height: 52px;
    width: 52px;
  }
  &.large {
    height: 62px;
    width: 62px;
  }
  .icon {
    position: absolute;
    right: -4px;
    bottom: -4px;
    padding: 4px;
    border-radius: 50%;
    &.incomplete {
      background: $black;
    }
    &.complete {
      background: var(--primary-color);
      color: $white;
      border: 2px solid $white;
    }
  }
}

.backgroundPath{
  stroke: $progress-bar-background;
}