@import 'styles/common.scss';

.customLink {
    border-radius: 12px;
    padding: 20px;
    background: var(--accent-text);
    column-gap: 12px;
    cursor: pointer;
    @include flex(flex-start);

    &:hover {
        background: var(--ui_teal_light-surface-primary-hover);
    }

    &Emoji {
        width: 44px;
        height: 44px;
        padding: 10px;
        border-radius: 100px;
        background: var(--surface-overlay-silent);
        position: relative;
        cursor: pointer;
        @include f-inter(2.5rem, 2.7rem);
        @include flex-center;

        .hoverClassIcon {
            display: none;

             svg {
                width: 20px;
                height: 20px;
             }
        }

        &Picker {
            position: absolute;
            top: 34px;
            left: 0;
            z-index: 1;
        }

        &:hover {
            .hoverClassIcon {
                @include flex-center;
            }

            .savedEmoji {
                display: none;
            }
        }
    }

    &Text {
        width: calc(100% - 92px);

        &Title {
            word-wrap: break-word;
            @include f-inter-bold(1.6rem, 2.2rem);
        }

        &Link {
            word-wrap: break-word;
            color: var(--text-icon-on-lights-secondary);
            @include f-inter-regular-2(1.3rem, 1.8rem);
        }
    }

    &Selection {
        background-color: transparent;
        border: none;
        padding: 0;
    }

    .tooltipContainer {
        width: 110px;
        white-space: normal;
        border-radius: 12px;
        background: rgba(9, 20, 37, 0.9);
        box-shadow: 0px 1px 20px 0px rgba(20, 30, 46, 0.12);
        backdrop-filter: blur(6px);
        padding: 8px 12px 8px 12px;
        @include flex-center;
        @include f-inter-regular(1.3rem, 1.8rem);

        &.shortTooltip {
            width: 90px;
        }
    }
}
