@import 'styles/common.scss';

.editShortLink {
  width: 460px;
  border-radius: 10px;

  &Header {
    position: relative;
    padding: 13px 10px;
    background: var(--bg-primary-webapp);
    @include flex-center;

    .title {
      @include f-inter-semi-bold(1.6rem, 2.2rem);
    }

    .closeIcon {
      position: absolute;
      right: 10px;
    }
  }

  &Content {
    background: var(--bg-primary-webapp);
    padding: 12px 28px 32px;

    .infoBox {
      color: var(--text-icon-on-lights-secondary);
      margin-bottom: 8px;
      @include f-inter-regular(1.4rem, 2.2rem);
    }

    .inputWrapper {
        margin-bottom: 16px;

        .inputBox {
          @include flex(center);
          border-radius: 10px;
          border: 1.5px solid var(--border-on-lights-15);
          padding: 11px 14px;
          background: var(--accent-text);

          &.errorBox {
            border: 1.5px solid var(--text_icon-on_lights-critical);
            background: var(--surface-critical-silent);
            box-shadow: 0px 1px 1px 0px rgba(8, 12, 11, 0.04);

            &:hover {
                border: 1.5px solid var(--text_icon-on_lights-critical);
            }

            .inputPrefix,
            input {
                background: var(--surface-critical-silent);
            }
          }

          &:hover {
            outline: none;
            border: 1.5px solid var(--text-icon-disabled);
            box-shadow: 0px 1px 3px rgba(8, 12, 11, 0.05);
          }

          .inputPrefix {
            margin-right: 4px;
            background: var(--accent-text);
            color: var(--text-icon-placeholder);
            @include f-inter-regular(1.6rem, 2.6rem);
          }

          input {
            padding: 0;
            border-radius: 0;
            border: none;

            &:hover {
              outline: none;
              border: none;
              box-shadow: none;
            }
          }
        }

        .error {
            color: var(--text_icon-on_lights-critical);
            margin-top: 4px;
            @include flex(center);

            p {
                margin-left: 5px;
                @include f-inter-regular-2(1.2rem, 1.7rem);
            }
        }
    }


    .consentBox {
      .checkBox {
        input {
          cursor: pointer;
          margin: 0;
        }

        label {
          margin-left: 8px;
          @include f-inter-regular-2(1.4rem, 2rem);
        }
      }
    }
  }

  &Action {
    padding: 28px;
    @include flex(center, flex-end);

    button {
      &:not(:last-child) {
        margin-right: 8px;
      }
    }

    .actionTooltip {
      width: 300px;
      border-radius: 12px;
      padding: 8px 12px;
      white-space: break-spaces;
      left: -35px;
      @include f-inter-regular(1.3rem, 1.8rem);
    }
  }
}
