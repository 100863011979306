@import 'styles/common.scss';

.InviteEmail {
  @include flex-column;
  height: 100%;
  background: var(--ui-teal-light-surface-tertiary);
  overflow: hidden;
  
  .header {
    @include flex-center;
    position: relative;
    @include f-inter-semi-bold(1.6rem, 2.2rem);
    padding: 10px;
    border-bottom: 1px solid var(--border-on-lights-10);
    &Icons {
      position: absolute;
      right: 20px;
    }
  
  }
  .content {
    @include flex;
    flex: 1;
    overflow: hidden;
  }
  .sidebar {
    width: 400px;
    padding: 20px 0 28px 12px;
   .title {
    @include f-inter-regular-2(1.4rem,2rem);
    color: var(--text-icon-on-lights-secondary);
    margin-bottom: 15px;
   }
    .candidate{
      &Row {
        margin-bottom: 8px;
        &:last-of-type {
          margin:0;
        }
      }
      &List{
        padding-right: 8px;
        height: 400px;
        overflow:auto;

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background: var(--surface-overlay-loud-plus);
          border-radius: 6px;
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
      .name {
        @include f-inter-regular-2(1.4rem, 2rem);
        text-transform: capitalize;
        @include multi-line-ellipsis(1);
        word-break: break-all;
      }
      .email {
        @include f-inter-regular(1.3rem, 1.8rem);
        color: var(--Text-Tertiary);
        @include multi-line-ellipsis(3);
        word-break: break-all;
      }
      
    }
  }}
  .separator {
    border-right: 1px solid var(--border-on-lights-10);
   
  }
 
 
  .main {
    width: 100%;
    height: 450px;
    overflow: auto;
    padding: 20px 20px 32px;
    .email-section {
      padding: 20px 0;
      border-radius: 8px;
      border: 1px solid var(--border-on-lights-10);
      background: var(--ui-teal-light-bg-primary);
      box-shadow: 0px 4px 8px 0px rgba(8, 12, 11, 0.08);
      .subject, .rsvp {
        @include f-inter-regular-2(1.6rem,2.6rem);
        padding: 0 16px;
      }
      .textarea {
        margin: 16px 0;
        background: var(--ui-teal-light-surface-tertiary);
        border: none;
        border-radius: 0;
      }
    }
    .checkbox{
      margin-top: 32px; 
      & > label {
      @include f-inter-regular-2(1.4rem,2rem);
      margin-left: 8px;
      }
    }
    .desc {
      @include f-inter-regular-2(1.4rem,2rem);
      color: var(--text-icon-on-lights-secondary);
      margin-top: 20px;;
    }
    .desc-invite-popup{
      @include flex(flex-start,center);
      flex-wrap: nowrap;
    }
  }
  .footer {
    padding: 28px;
    border-top: 1px solid var(--border-on-lights-10);
    background: var(--ui-teal-light-bg-primary);
    .button {
      &Row {
        @include f-inter-semi-bold(1.6rem, 2.2rem);
        color: var(--text-icon-on-lights-secondary);
      }
    }
  }
  .button {
    &Text {
      color: var(--text-icon-on-lights-tertiary);
      padding: 0;
      &:hover {
        background: none;
      }
    }
//     &Icon {
//       width: 18px;
// height: 18px;
//     }
  }
 
}
