@import 'styles/common.scss';

.AddLinkTree {
    width: 460px;
    max-height: calc(100vh - 40px);
    border-radius: 16px;
    background-color: var(--ui-teal-light-surface-tertiary);

    &Header {
        position: relative;
        height: 48px;
        @include flex-center;

        p {
            @include f-inter-semi-bold(1.6rem, 2.2rem);
        }

        .closeIcon {
            position: absolute;
            top: 10px;
            right: 16px;
        }
    }

    &Content {
        padding: 12px 28px 32px;

        .contentTitle {
            color: var(--text-icon-on-lights-secondary);
            @include f-inter-regular(1.4rem, 2.2rem);
        }

        .inputUrl {
            margin-top: 16px;

            &.success {
                input {
                    border: 1.5px solid var(--primary-color);
                    background: var(--surface-brand-silent);
                    box-shadow: 0px 1px 1px 0px rgba(8, 12, 11, 0.04);
                }
            }
        }

        .linkTreeData {
            margin-top: 16px;

            .title {
                letter-spacing: -0.176px;
                @include f-inter-regular(1.6rem, 2.6rem);
            }

            .customLinks {
                margin: 16px 0;
                row-gap: 8px;
                @include flex-column();
            }
        }
    }

    &Action {
        padding: 28px;
        background: var(--accent-text);
        @include flex(center, flex-end);
    }
}
