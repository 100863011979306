@import 'styles/common.scss';

.container {
  border-radius: 14px;
  background-color: rgba(9, 20, 37, 0.1);
  .progress {
    height: 100%;
    border-radius: 14px;
    &White {
      background: var(--bg-primary);
    }
    &Primary {
      background: var(--primary-color);
    }
    &Yellow {
      background: var(--ui-teal-light-surface-warning);
    }
  }
}
