@import 'styles/common.scss';

.InviteManually {
  @include flex-column;
  gap: 20px;

  .TextArea {
    @include flex-column;

    &Container {
      @include flex(center);
      gap: 12px;
    }

    &Element {
      @include flex-wrap;
      @include fns-semi-bold(1.6rem, 2.4rem);
      gap: 4px;
      width: 100%;
      max-width: 516px;
      color: var(--text-secondary);
      background: var(--bg-primary);
      border-radius: 10px;
      padding: 11px 14px;
      border: 1.5px solid var(--selection-text-v4);
      resize: none;
      box-shadow: 0px 1px 1px 0px var(--selection-text-v3);


      &WithIcon {
        padding: 11px 33px 11px 11px;
        overflow: hidden;
      }

      &[disabled],
      &[readonly] {
        color: var(--bg-disabled);
      }

      &:focus {
        outline: none;
        border-color: var(--primary-color-light);
      }

      &::placeholder {
        @include f-inter-regular(1.6rem, 2.6rem);
        color: var(--text-icon-placeholder);
      }

      &:hover {
        border: 1.5px solid var(--border-on-lights-3);
        box-shadow: 0px 1px 3px var(--surface-overlay-silent);
      }
    }

    &Gap {
      gap: 4px;
    }

    &Label {
      @include flex(center, space-between);

      label {
        @include flex(center);
        @include f-inter-regular-2(1.4rem, 2.0rem);
        margin-bottom: 8px;
        color: #080C0B;

        .optional {
          @include f-inter-semi-bold(1.1rem, 1.3rem);
          color: rgba(8, 12, 11, 0.5);
          padding-left: 4px;
        }

        .infoToolTip {
          padding-left: 4px;
          line-height: 0;
        }

        &.HelperText {
          color: var(--complementary-secondary);
        }
      }
    }



    &IsInvalid {
      textarea {

        &,
        &:focus {
          background: var(--surface-critical-silent);
          box-shadow: 0px 1px 1px rgba(8, 12, 11, 0.04);
          border: 1.5px solid #e5132e !important;
        }
      }
    }

    .EmailTag {
      display: inline-flex;
      margin: 0 4px 4px 0;
      padding: 2px 6px;
      background-color: var(--tag-background-color);
      border-radius: var(--tag-border-radius);
      align-items: center;

      button {
        margin-left: 4px;
        background: none;
        border: none;
        cursor: pointer;
        color: var(--tag-remove-button-color);
      }
    }

    .closeIcon {
      div {
        width: auto;
        height: auto;
        min-width: auto;

        svg {
          width: 9.35px;
          height: 9.25px;
        }
      }

      &InValid {
        div {
          svg {
            color: var(--text_icon-on_lights-critical);
          }
        }
      }
    }

    .emailChip {
      @include flex(center);
      padding: 4px 8px;
      gap: 4px;
      border: 1px solid var(--surface-overlay-loud-plus);
      height: 26px;
      border-radius: 6px;
      width: max-content;

      &Text {
        @include f-inter-regular-2(1.3rem, 1.8rem);
        color: rgba(8, 12, 11, 1);
      }

      &InValid {
        border: 1.5px solid var(--text_icon-on_lights-critical);
        background-color: var(--surface-critical-silent);
      }

      &.is-focused {
        border: 1.5px solid var(--button-danger);
      }
    }

    .inputElement {

      @include fns-semi-bold(1.6rem, 2.4rem);
      border: none;
      color: #383838;
      background: var(--bg-primary);
      flex: 1;
      min-width: 100px;

      &[disabled],
      &[readonly] {
        color: var(--bg-disabled);
      }

      &::placeholder {
        @include fns-regular(1.8rem, 2.5rem);
        color: #989898;
      }

      &:focus-visible {
        outline: none;
      }

      &[type='password'] {

        &::-ms-clear,
        &::-ms-reveal {
          display: none;
        }
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      &[type='number'] {
        appearance: textfield;
        -moz-appearance: textfield;
      }

      &:blank {
        color: var(--text-disabled);
      }

    }

    .submitButton {
      min-width: 92px;
      height: 48px;
      padding: 13px 20px;
    }

  }

  .uploadCSV {
    @include flex-column;
    gap: 20px;
    cursor: pointer;
    &Label {
      @include f-inter-regular-2(1.4rem, 2.0rem);
      margin-bottom: 8px;
      color: var(--surface-inverted);
    }

    &Content {
      @include flex-column(center, center);
      background: rgba(255, 255, 255, 1);
      width: 620px;
      height: 160px;
      padding: 11px 14px;
      border-radius: 10px;
      .fileIcon {
        svg {
          &:hover {
            color: var(--selection-text-v2);
          }
        }
      }

    }

    &Heading {
      @include f-inter-semi-bold(1.6rem, 2.6rem);
      color: var(--text-primary-v2);
    }

    &Text {
      @include f-inter-regular(1.3rem, 1.8rem);
      color: #717171;
    }

    &ErrorContainer {
      height: 72px;
      padding: 16px 20px;
      border-radius: 20px;
      border: 0.5px solid var(--border-on-lights-1);
      box-shadow: 0px 1px 2px 0px var(--black-color-04);
      background: var(--surface-critical-silent);
      @include flex(center, space-between);
      gap: 10px;

      .errorSection {
        @include flex(center);
        gap: 10px;
      }

      .infoIcon {
        color: var(--text_icon-on_lights-critical);
      }

      .infoText {
        @include f-inter-semi-bold(1.6rem, 2.2rem);
        color: var(--text_icon-on_lights-critical);
      }

      .cancelIcon {
        color: var(--text-icon-on-lights-secondary);
      }
    }
    .textbutton{
      @include f-inter-regular(1.3rem,1.8rem);
      padding: 0;
      &:hover {
        background: none;
        text-decoration: underline;
      }
    }
  }

  .loader {
    bottom: 25%;
  }

  .import-count-message {
    position: relative;
    @include f-inter-semi-bold(1.6rem,2.2rem);
    border-radius: 20px;
    border: 0.5px solid var(--border-on-lights-10);
    background: var(--surface-warning-silent);
    box-shadow: 0px 1px 2px 0px rgba(9, 20, 37, 0.04);
    padding: 16px 20px;
    .closeIcon{
      position: absolute;
      right: 20px;
    }
  }
  .candidateList {
    height: 400px;
    overflow: auto;
   @include scroll-bar-style();
   .selection {
      background: none;
      padding: 8px;
      &:hover {
        border-radius: 8px;
        background: var(--ui-teal-light-bg-secondary);
      }
      .name {
        @include f-inter-regular-2(1.6rem, 2.2rem);
        text-transform: capitalize;
        
      }
      .email {
        @include f-inter-regular(1.3rem, 1.8rem);
        color: var(--Text-Tertiary);
      }
      .name,.email {
        @include multi-line-ellipsis(1);
        word-break: break-all;
      }
    }
    .in-event{
      @include f-inter-regular-2(1.2rem,1.7rem);
      color: var(--text-icon-tertiary);
      border-radius: 6px;
      background: var(--surface-overlay-silent);
      padding: 2.5px 4px;
      &-candidate {
        padding: 8px;
        opacity: 0.5;
        .name,.email {
          color: var(--text-icon-disabled);
        }
      }
      & > input:not(:checked) ~ label{
  
        .untickDisabled{
          border: 1.5px solid var(--border-on-lights-10);
          background: var(--surface-transparent-secondary-loud );
        }
      }
    }
  }
}