@import 'styles/common.scss';

.ProgressReferral {
  width: calc(100% - 12px);

  .StepProgress {
    position: relative;
    margin-left: 44px;
    list-style: none;

    .StepProgressItem {
      position: relative;
      counter-increment: list;
      padding: 2px 0px 24px 0px;

      h4 {
        @include f-inter-semi-bold(1.4rem, 2rem);
      }

      p {
        @include f-inter-regular(1.3rem, 1.8rem);
      }

      &.current {
        h4 {
          color: var(--primary-color);
        }

        p {
          color: var(--primary-color);
        }
      }

      &::before {
        display: inline-block;
        content: '';
        position: absolute;
        left: -29px;
        height: 100%;
        max-height: 50px;
        width: 2px;
        background: var(--surface-secondary-hover);
        bottom: 4px;
      }

      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        left: -40px;
        width: 21px;
        height: 21px;
        border: 1.5px solid var(--surface-secondary-hover);
        border-radius: 50%;
        background-color: var(--bg-primary-light);
      }

      &.isDone::before {
        background-color: $primary-color;
      }

      .tick {
        color: $primary-color;
        position: absolute;
        bottom: 58px;
        left: -40px;
        z-index: 1;
      }

      &.isDone {
        &::after {
          border: 0px;
        }
      }

      .newCircle {
        width: 24px;
        height: 24px;
        border: 7px solid var(--primary-color);
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: -40px;
        z-index: 1;
        box-shadow: 0px 0px 0px 4px #00949133;
      }
    }

    .StepProgressItem:last-child::before {
      display: none;
    }
  }
}
