@import 'styles/common.scss';

.Popover{
    width: 100%;
    .TriggerWrapper{
        width: auto;
    }
    &Container{
        border-radius: 8px;
        border: 0.5px solid #080C0B1A;
        width: 420px;
        max-height: calc(100vh - 80px);
        overflow: auto;
        margin: 40px 0px;
        z-index: 150;
        .menu{
            box-shadow: 0px 8px 16px 0px #080C0B17;
            background-color: var(--accent-text);
            padding: 24px;
        }
           
    }
}