@import 'styles/common.scss';

.Invite {
  @include flex-column;
  height: 100%;
  background: var(--ui-teal-light-surface-tertiary);
  overflow: hidden;
  &Popover {
    width: 400px;
    overflow: hidden;
    border-radius: 8px;
    border: 0.5px solid var(--border-on-lights-10);
    box-shadow: 0px 8px 16px 0px rgba(8, 12, 11, 0.09);
    background: var(--ui-teal-light-bg-primary);
    padding: 24px;
    z-index: 152;
    .menu {
      padding: 0px;
    }
  
    &Trigger {
      @include flex(unset, space-between);
  
      &:hover {
        background: #080C0B0D;
        border-radius: 6px;
      }
    }
  }
  .header {
    @include flex-center;
    position: relative;
    @include f-inter-semi-bold(1.6rem, 2.2rem);
    padding: 10px;
    border-bottom: 1px solid var(--border-on-lights-10);
    &Icons {
      position: absolute;
      right: 20px;
    }
    &Details {
      @include flex-center;
      column-gap: 8px;
      padding: 5.5px 10px;
      @include f-inter-semi-bold(1.2rem, 1.7rem);
      border-radius: 99px;
      background: var(--surface-overlay-silent);
      cursor: pointer;
    }
    .progressCircle {
      stroke: var(--text-primary-v2);
    }
  }
  .content {
    @include flex;
    flex: 1;
  }
  .content-mp{
    max-height: 526px;
  }
  .sidebar {
    width: 240px;
    &Row {
      padding: 10px 12px;
      cursor: pointer;
      margin-bottom: 4px;
      color: var(--text-icon-on-lights-tertiary);
      &:hover,
      &Active {
        background: var(--ui-teal-light-bg-primary);
        border-radius: 6px;
      }
    }
    &Text {
      flex: 1;
    }
    &Text,
    &Count {
      @include f-inter-regular(1.4rem, 2rem);
    }
    &TextActive {
      font-weight: 600;
      color: var(--text-primary-v2);
    }
    &IconActive {
      color: var(--primary-color);
    }
    &Top {
      padding: 20px 12px 0 12px;
    }
    &Bottom {
      overflow: auto;
      height: calc( 100% - 200px );
      max-height: 310px;
      @include scroll-bar-style();
      padding: 0 12px 28px;
    }
  }
  .separator {
    border-right: 1px solid var(--border-on-lights-10);
    &Sidebar {
      margin: 16px 0;
    }
    &Content {
      margin: 12px 0;
    }
  }
  .separatorSidebar,
  .separatorContent {
    border-bottom: 1px solid var(--border-on-lights-10);
  }
  .pastEvents {
    border-radius: 6px;
    padding: 6px 8px;
    cursor: pointer;
    &:hover {
      background: var(--ui-teal-light-bg-primary);
    }
    &Title {
      @include f-inter-semi-bold(1.3rem, 1.8rem);
      color: var(--text-icon-on-lights-tertiary);
      padding: 0 8px 8px 8px;
    }
    &Text {
      @include f-inter-regular-2(1.2rem, 1.7rem);
      color: var(--text-icon-on-lights-tertiary);
      padding-top: 14px;
    }
    &Name {
      @include f-inter-regular-2(1.4rem, 2rem);
      @include multi-line-ellipsis(1);
      word-break: break-all;
    }
    &Detail {
      @include f-inter-regular-2(1.2rem, 1.7rem);
      color: var(--text-icon-on-lights-tertiary);
    }
  }
  .main {
    @include flex-column;
    @include scroll-bar-style;
    flex: 1;
    padding: 0 20px 20px 20px;
    .fixContainer{
      position: sticky;
      top: 0;
      z-index: 10;
      background: var(--ui-teal-light-surface-tertiary);
      padding-top: 20px;
    }
    .spinner {
      @include flex-center;
      width: 100%;
    }
    .candidateList{
      @include scroll-bar-style();
      max-height: 440px;
      overflow-y: auto;
      overflow-x: hidden;
      margin-right: -18px;
     
      &Empty {
        @include flex-center;
        min-height: 200px;
        @include f-inter-semi-bold(1.6rem,2.2rem);
        color: var(--text-icon-on-lights-secondary);
      }
     
    }
    .candidate-list-past-events{
      max-height: 385px;
    }
    .no-scroll {
      overflow: hidden;
      background: linear-gradient(180deg, var(--ui-teal-light-surface-tertiary) 0%, var(--surface-surface-transparent-secondary-loud) 95.43%);
    }
    .empty-state{
      @include flex-column(center,center);
      height: 100%;
     
      .title{
        @include f-inter-bold(2rem,2.8rem);
        padding-top: 16px;
        padding-bottom: 32px;
      }
    }
    .notificationStub {
      @include flex-column(center,center);
      @include f-inter-regular-2(1.3rem, 1.8rem);
      background: var(--surface-surface-transparent-secondary-loud);
      padding: 16px 80px;
      color: var(--text-icon-on-lights-secondary);
      border-radius: 8px;
      margin-bottom: 12px;
        text-align: center;
        .title{
        @include f-inter-bold(2rem,2.8rem);
      }
      .desc{
        @include f-inter-regular(1.4rem,2.2rem);
        padding-top:4px;
      }
      .button {
        margin-top:20px;
      }
    }
    .search-box {
      margin-bottom: 12px;
      &-input {
        background: var(--surface-overlay-silent);
        border:none;
        border-radius: 12px;
        &::placeholder{
          color: var(--text-icon-placeholder);
        }
      }
      .searchIcon{
        color: var(--text-icon-placeholder);
      }
    }
    .button {
      &TextSelectall {
        color: var(--text-icon-on-lights-secondary);
        &:disabled {
          background: none;
          border: none;
          color: var(--text-icon-disabled);
        }
      }
      &Row { 
        .buttonContainer{
          column-gap: 8px;
        }
        .action-tooltip{
          border-radius: 12px;
          padding: 8px 12px;
          @include f-inter-regular(1.3rem, 1.8rem);
        }
        .clear{
          @include f-inter-regular-2(1.4rem,2rem);
          color: var(--text-icon-tertiary);
          cursor: pointer;
        }
       
        .settings-icon-button{
          background-color: var(--text-icon-text-icon-inverted-primary);
          padding: 9px;
          border-radius: 8px;
          &:hover{
            background: var(--ui_teal_light-surface-primary-hover);
          }
          & > div {
            min-width: auto;
            width: 14px;
            height: 14px;
            color: var(--text-primary-v2);
         
          }
        }
        .filterSelected {
          background: var(--ui_teal_light-surface-primary-pressed);
        }
      }
    }
    .filtersRow {
      margin-top: 12px;
    }
    .invite-via-manual{
      padding: 20px 0 32px;
      max-height: 500px;

    }
  }
 
  .footer {
    padding: 28px;
    position: absolute;
    bottom: 0;
    border-top: 1px solid var(--border-on-lights-10);
    background: var(--ui-teal-light-bg-primary);
    .button {
      &Row {
        @include f-inter-semi-bold(1.6rem, 2.2rem);
        color: var(--text-icon-on-lights-secondary);
      }
    }
  }
  .button {
    &Text {
      color: var(--text-icon-on-lights-tertiary);
      &:hover {
        background: none;
      }
      &Disabled {
        background: none;
        border: none;
        color: var(--text-icon-disabled);
      }
    }
  }
}
.dropdown{
  width: 20%;
 
  &-trigger{
    @include f-inter-semi-bold(1.3rem,1.8rem);
    background: var(--ui-teal-light-bg-primary);
    border:none;
    padding: 7px 12px;
    border-radius: 8px;
  }
  &-options {
    @include f-inter-regular-2(1.3rem,1.8rem);
    top: 35px !important;
    min-width: 0 !important;
  }
}
.action-menu{
    @include flex(center);
    position: relative;
    min-width: 133px;
}
.action-menu-event{
  min-width: 155px;
}
.popover-invite-left {
  &-container {
    width: 400px;
    border-radius: 8px;
    border: 0.5px solid var(--border-on-lights-10);
    box-shadow: 0px 8px 16px 0px rgba(8, 12, 11, 0.09);
    background: var(--ui-teal-light-bg-primary);
    padding: 24px;
    cursor: pointer;
    }
    .title {
      color: var(--black-color);
      @include f-inter-semi-bold(1.8rem,2.5rem);
      padding-bottom: 20px;
    }
    .inviteCount{
      @include f-inter-bold(2rem,2.8rem);
      .count{
        color: var(--black-color);
        margin-right: 1px;
      }
      .countText {
        @include f-inter-regular-2(1.3rem,1.8rem);
        color: var(--text-icon-on-lights-tertiary);
      }
      &Container {
      padding: 12px 20px 16px;
      border-radius: 8px;
      background: var(--surface-overlay-silent);
      &Title {
        @include f-inter-regular-2(1.2rem,1.7rem);
        color: var(--text-icon-on-lights-secondary);
        padding-bottom: 8px;
        & >span {
          font-weight: 600;
        }
      }
      .progressBar{
        margin-top: 4px;
        height: 6px;
      }
    }
  }
  .subtitle {
    @include f-inter-semi-bold(1.6rem,2.2rem);
    color: var(--black-color);
    padding-top: 20px;
    &Desc {
      @include f-inter-regular(1.3rem,1.8rem);
      color: var(--text-icon-on-lights-tertiary);
      padding: 8px 0 4px;
    }
  }
}
