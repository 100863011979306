@import 'styles/common.scss';

.Sidebar {
  max-height: 100vh;
  @include flex-column(center, space-between);
  padding: 0;
  width: 100%;
  min-width: 272px;
  background: var(--bg-primary);
  color: var(--text-primary-v2);

  position: sticky;
  top: 0;
  overflow-y: initial;
  border-right: 1px solid var(--border-on-lights-10);
  .header {
    width: 100%;
    padding: 16px 12px;
    cursor: pointer;

    .profile {
      .dropdown-menu {
        padding: 8px 12px;
        width: 100%;
        border: none;
        background: transparent;

        &:hover,
        &.dropdown-open {
          background: var(--ui_teal_light-surface-primary-pressed);
          border-radius: 6px;
        }
        .icon {
          color: var(--text-primary-v2);
          transform: rotateX(0deg);
          transition: transform 500ms;
        }
        &[data-headlessui-state="open"] {
          .icon {
            transform: rotateX(180deg);
            transition: transform 500ms;
          }
        }
      }

      .options {
        position: absolute;
        top: 48px;
        right: 0;
        background-color: var(--bg-primary);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
        border-radius: 10px;
        padding: 8px;
        width: 160px;
        z-index: $zindex-dropdown;
      }
      .logout {
        @include f-inter-regular(1.5rem, 2.2rem);
        border: none;
        border-radius: 6px;
        padding: 11px 14px;
        width: 100%;
        height: 44px;
        text-align: left;
        color: var(--black-color);
        background-color: transparent;
        &:hover {
          background-color: var(--ui_teal_light-surface-primary-pressed);
        }
      }
      .title {
        max-width: 200px;
        position: relative;
      }
      .avatar {
        flex-shrink: 0;
        width: 40px;
        height: 40px;
      }
      .name {
        @include f-inter-regular(1.4rem, 2.5rem);
        text-align: start;
      }
    }

    .section {
      padding-top: 16px;
      &Row {
        padding: 10px 12px;
        margin-bottom: 4px;
        cursor: pointer;
        @include f-inter-regular(1.4rem, 2rem);
        &:last-of-type {
          margin: 0;
        }
        &:hover {
          background: #080C0B0D;
          border-radius: 6px;
        }
        .iconActive {
          color: var(--primary-color);
        }
        .textActive {
          font-weight: 600;
        }
        &Active {
          background: #080C0B0D;
          border-radius: 6px;
          &:hover {
            background: #080C0B0D;
          }
        }
      }
    }
    .new {
      @include f-inter-regular(1.1rem, 1.5rem);
      @include flex(flex-end);
      background: var(--text_icon-on_lights-critical);
      border-radius: 100px;
      color: var(--accent-text);
      height: 20px;
      min-width: 20px;
      padding: 1.5px 6px 2.5px 6px;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
    }
  }

  .footer {
    width: 100%;
    .separator {
      border-bottom: 1px solid var(--border-on-lights-10);
    }
    .logo {
      padding: 24px 28px;
      cursor: pointer;
    }
  }

  .divider{
    background-color: rgba(8, 12, 11, 0.1);
    margin: 16px 12px;
    height: 1px;
  }
}
