@import 'styles/common.scss';

.HuzzleVerified {
    width: 100%;
    margin-bottom: 40px;
    background: var(--accent-text);
    border-radius: 8px;
    box-shadow: 0px 1px 3px 0px rgba(8, 12, 11, 0.05);

    &Header {
        padding: 24px;
        @include flex(center);

        .medalIcon {
            margin-right: 12px;
            height: 32px;
            svg > path {
                fill: var(--primary-color);
            }
        }

        .title {
            @include f-inter-bold(1.8rem, 2.5rem);
        }

        .caption {
            color: var(--text-icon-on-lights-tertiary);
            @include f-inter-regular(1.4rem, 2rem);
        }
    }

    &Content {
        width: calc(100vw - 272px - 96px);
        padding-left: 24px;
        padding-bottom: 24px;

        .verifiedCard {
            width: 280px;
            height: 240px;
            padding: 24px;
            border-radius: 10px;
            background: var(--ui-teal-light-surface-tertiary);
            @include flex-column(center, space-between);

            &.isComplete {
                .checkIconContainer {
                    .checkIcon {
                        border: none;
                        background: var(--primary-color);
                        box-shadow: 0px 1px 1px 0px rgba(1, 67, 66, 0.05);

                        svg {
                            path {
                                fill-opacity: 1;
                                fill: var(--accent-text);
                            }
                        }
                    }
                }

                .completeText {
                    padding: 10px 16px;
                    color: var(--primary-color);
                    @include flex-center;
                    @include f-inter-semi-bold(1.4rem, 2rem);

                    .doubleCheckIcon {
                        margin-right: 8px;
                    }
                }
            }

            .checkIconContainer {
                margin-bottom: 16px;
                @include flex-center;

                .checkIcon {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    border: 1.5px solid var(--text-icon-disabled);
                    @include flex-center;

                    svg {
                        width: 12px;
                        height: 12px;

                        path {
                            fill-opacity: 0.3;
                        }
                    }
                }
            }

            .infoSection {
                height: calc(100% - 40px);
                @include flex-column(center, space-between);
            }

            .verificationStep {
                margin-bottom: 16px;
                @include flex-column(center, center);

                .stepTile {
                    margin-bottom: 8px;
                    @include f-inter-bold(1.6rem, 2.2rem);
                }

                .stepDetails {
                    white-space: break-spaces;
                    text-align: center;
                    color: var(--text-icon-on-lights-tertiary);
                    @include f-inter-regular(1.4rem, 2.2rem);
                }
            }

            .actionMenuCta {
                width: 100%;

                button {
                    width: 100%;
                }

                ul {
                    bottom: 120%;
                    top: unset;
                }

                & > button > button > button {
                    border: none;
                    padding: 10px 16px;

                    div:nth-child(2) {
                        width: 12px;
                        height: 12px;
                        margin-left: 8px;

                        svg {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }
            }
        }

        .navigationButton {
            background: transparent;

            button {
                border: 1.5px solid var(--border-border-15, rgba(8, 12, 11, 0.15));
                box-shadow: 0px 1px 3px 0px rgba(8, 12, 11, 0.05);

                &:hover {
                    background: var(--ui_teal_light-surface-primary-hover);
                    border-radius: 50%;
                }
            }
        }
    }

    &Popover {
        box-shadow: none;
        margin-bottom: 0;

        & > div {
            width: 100%;
        }

        .HuzzleVerifiedHeader {
            padding: 0;
            margin-bottom: 20px;
            @include flex(flex-start);

            .medalIcon {
                margin-right: 8px;

                svg {
                    width: 24px;
                    height: 24px;
                }
            }

            .title {
                @include f-inter-semi-bold(1.8rem, 2.5rem);
            }
        }

        .HuzzleVerifiedContent {
            padding: 0px;
            width: auto;
        }

        .verifiedCard {
            height: auto;
            width: 100%;
            margin-bottom: 8px;
            padding: 12px 12px 12px 16px;
            flex-direction: row-reverse;
            column-gap: 10px;
            @include flex(center, space-between);

            &:last-child {
                margin-bottom: 0px;
            }

            .checkIconContainer {
                width: 20px;
                margin-bottom: 0px;

                .checkIcon{
                    width: 20px;
                    height: 20px;
                }
            }

            .infoSection {
                height: 100%;
                row-gap: 8px;
                @include flex-column(flex-start, space-between);
            }

            .actionCta {
                border: none;
                border-radius: 8px;
                padding: 7px 12px;
                @include f-inter-semi-bold(1.2rem, 1.8rem);
            }

            .verificationStep {
                align-items: flex-start;
                margin-bottom: 0px;

                .stepTile {
                    margin-bottom: 2px;
                    @include f-inter-semi-bold(1.4rem, 2rem);
                }

                .stepDetails{
                    text-align: start;
                    color: var(--text-icon-on-lights-secondary);
                    @include f-inter-regular(1.4rem, 2rem);
                }

                .completed{
                    color: var(--text-icon-on-lights-tertiary);
                }
            }

            .actionMenuCta {
                width: auto;

                & > button {
                    width: auto;

                    & > button > button {
                        border-radius: 8px;
                        padding: 7px 12px;
                        @include f-inter-semi-bold(1.2rem, 1.8rem);

                        div:nth-child(2) {
                            width: 10px;
                            height: 10px;
                            margin-left: 6px;

                            svg {
                                width: 10px;
                                height: 10px;
                            }
                        }
                    }
                }

                ul {
                    width: 150px;
                    right: -15px;
                }
            }

            .add-subscriber-button {
                padding: 7px 12px;
                border: none;
                font-size: 12px;
            }

            .addExternalLinks {
                ul {
                    width: 235px;
                    left: 0;
                }
            }
        }
    }
}
