@import 'styles/common.scss';
$zindex-dropdown: 999;

.GroupedMultiSelect {
  position: relative;
  display: inline-flex;
  white-space: nowrap;
  .trigger {
    @include ui-text-ui-14-semibold;
    @include flex(center);
    column-gap: 8px;
    border: 0;
    background-color: var(--surface-overlay-silent);
    color: var(--text-primary-v2);
    border-radius: 10px;
    padding: 9px 16px;
    border: 1px solid transparent;
    cursor: pointer;
    @include small {
      padding: 4px 8px;
    }
    &:disabled {
      color: var(--text-tertiary);
    }
    &.selected {
      background-color: var(--primary-color);
      color: var(--text-icon-text-icon-inverted-primary);
    }
    .full-width {
      width: 100%;
      p{
        width: 100%;
      }
    }
    .has-border {
      border: 1px solid var(--border-color);
      @include sm-desktop-up {
        border: none
      }
    }
  }
  .icon {
    &.reverse {
      transform: rotateX(180deg);
    }
    &,
    &.reverse {
      transition: transform 0.2s;
    }
  }
  .dropdown {
    position: absolute;
    width: auto;
    background: var(--bg-primary);
    border-radius: 10px;
    box-shadow: 0px 0px 10px var(--shadow-color);
    z-index: $zindex-dropdown;
    min-width: 272px;
    @include small {
      max-width: 320px;
    }
    &.align {
      &-Left {
        left: 0;
      }
      &-right {
        right: 0;
      }
      &-center {
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .search {
      padding: 14px 14px 4px 14px;
      .input {
        color: var(--text-primary);
        input {
          @include ui-text-ui-16-regular;
          background-color: var(--ui-teal-light-surface-overlay-silent);
          padding: 7px 12px 7px 33px;
          border: none;
          &::placeholder {
            @include ui-text-ui-16-regular;
            color: var(--text-icon-placeholder);
          }
        }
      }
      .search-icon {
        left: 4px;
        color: var(--text-icon-placeholder);
      }
    }
    .options {
      padding: 8px 4px 4px;
      height: auto;
      max-height: 292px;
      overflow: auto;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: var(--scroll-bar-color);
        box-shadow: 0 0 1px rgba($color: $black, $alpha: 0.5);
      }
      li {
        @include flex(center);
        column-gap: 8px;
        color: var(--text-primary);
        padding: 7px 4px 7px 10px;
        width: 100%;
        border-radius: 10px;
        cursor: default;
        @include small {
          padding-right: 0;
        }
        &:hover {
          background: var(--bg-secondary);
        }
      }
    }
    .option {
      &.selected {
        &:not(.GroupedMultiSelectDropdownOptionLabelFirst) {
          background: var(--bg-secondary);
        }
      }
      &.sub-option {
        margin-left: 26px;
        width: auto;
      }
      .option-icon {
        color: var(--text_icon-secondary);
        transform: rotateZ(-90deg);
        &.expanded {
          transform: rotateZ(0deg);
        }
        &.invisible {
          visibility: hidden;
          pointer-events: none;
        }
        &:hover {
          border-radius: 4px;
        }
      }
      .checkbox {
        width: 100%;
        label {
          @include fns-regular(1.4rem, 1.6rem);
          color: var(--text-primary);
          width: 100%;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:disabled {
            color: red;
          }
        }
        img {
          margin: 0 6px 0 2px;
          height: 18px;
          width: auto;
        }
      }
    }
    .search ~ .options {
      padding: 4px;
    }

    .option:has( > .option-icon:hover) {
      background-color: transparent;
    }
    .no-results {
      @include fns-regular(1.6rem, 1.9rem);
      padding: 0 14px 16px;
      text-align: center;
    }
    &.position-top {
      bottom: calc(100% + 10px);
    }
    &.position-bottom {
      top: calc(100% + 10px);
    }
    &.full-width {
      width: 100%;
    }
  }
  .valuesEllipsis {
    @include multi-line-ellipsis(1);
    text-align: left;
  }
  &FullWidth {
    width: 100%;
  }
  .actions {
    @include flex(center, flex-end);
    column-gap: 8px;
    padding: 12px 14px;
    border-top: 1px solid var(--border-border-10);
    border-radius: 0 0 10px 10px;

    .show-button {
      min-width: 100px;
    }
  }
}

.spinner {
  @include flex-center;
  padding-bottom: 16px;
}
