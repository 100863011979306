@import 'styles/common.scss';

.SocietyProgressBar {
    .progressContainer {
        width: 100%;
        background: var(--ui-teal-light-surface-brand-silent);
        border-radius: 6px;
        padding: 10px 12px;
        position: relative;
        margin-top: 8px;
    }

    .medalIcon{
        color: var(--primary-color);
        margin-right: 4px;
        width: 20px;
        height: 20px;
    }

    .closeIcon{
        padding: 2px;
        div{
            width: auto;
            height: auto;
            svg{
                width: 11.56px;
                height: 11.56px;
            }
        }
    }
    
    .progressBar {
        background: var(--primary-color);
        opacity: 0.10;
        transition: width 0.3s ease;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        border-radius: 6px;
    }
    
    .progressInfo {
        position: relative;
        z-index: 2;
        @include flex(center, space-between);
        color: var(--text-primary-v2);
        
        .text{
            @include f-inter-regular-2(1.3rem, 1.8rem);
            margin-right: auto;
        }

        .percentage{
            @include f-inter-semi-bold(1.3rem, 1.8rem);
            margin-left: auto;
            span{
                @include f-inter-regular(1.2rem, 1.7rem);
                color: var(--text-icon-on-lights-secondary);
                margin-left: 3px;
            }
        }
    }
}
